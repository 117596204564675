import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import AddTask from '@mui/icons-material/AddTask';
import DeleteForever from '@mui/icons-material/DeleteForever';
import {
    Paper,
    Dialog,
    Typography,
    AppBar,
    Toolbar,
    IconButton,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Fab,
    Tooltip,
    FormControl,
    Select,
    MenuItem,
    InputLabel
} from "@mui/material";
import PropTypes from "prop-types";

import f_publicacoes from "../../services/f_publicacoes";

const ComparePaper = styled(Paper)(({ theme }) => ({
    width: '100%',
    height: '50vmin',
    textAlign: 'center',
    overflow: 'auto'
}));

const InfosPaper = styled(Paper)(({ theme }) => ({
    padding: '1vmin',
    textAlign: 'center'
}));

function ViewPublicacaoDialog(props) {
    const [tmpMotivoInclusao, setTmpMotivoInclusao] = React.useState(undefined);
    const [tmpDescricaoArquivamento, setTmpDescricaoArquivamento] = React.useState(undefined);

    useEffect(() => {
        if (tmpMotivoInclusao === undefined) {
            setTmpMotivoInclusao(props.publicacaoData?.motivo_inclusao);
        }

        if (tmpDescricaoArquivamento === undefined) {
            setTmpDescricaoArquivamento(props.publicacaoData?.descricao_arquivamento);
        }

        console.log('update ViewPublicacaoDialog');
	}, [props.publicacaoData?.motivo_inclusao, props.publicacaoData?.descricao_arquivamento, tmpMotivoInclusao, tmpDescricaoArquivamento]);

    const handleSetMotivoInclusao = (event) => {
        setTmpMotivoInclusao(event.target.value);
        if (props.publicacaoData.doc_id !== undefined) {
            f_publicacoes.update(props.publicacaoData.doc_id, {
                motivo_inclusao: event.target.value
            })
        }
    };

    const handleSetMotivoArquivamento = (event) => {
        setTmpDescricaoArquivamento(event.target.value);
        if (props.publicacaoData.doc_id !== undefined) {
            f_publicacoes.update(props.publicacaoData.doc_id, {
                descricao_arquivamento: event.target.value
            })
        }
    };

    function highlightText(string1, string2) {
        let words = string1.split(" ");

        let output = [];
        for (var idx = 0; idx < words.length; idx++) 
        {
            let word = words[idx];
            if (string2.includes(word)) {
                output.push(<span key={word + idx} style={{ backgroundColor: 'yellow' }}>{word + " "}</span>);
            } else {
                output.push(<span key={word + idx}>{word + " "}</span>);
            }
        };

        return output;
    }

    const handleClose = () => {
        props.handleClose(false, undefined);
        setTmpMotivoInclusao(undefined);
        setTmpDescricaoArquivamento(undefined);
    };

    const handleClickEnviarProcessamento = () => {
        props.handleClose(false, undefined);
        f_publicacoes.update(props.publicacaoData.doc_id, {
            status: "aguardando_processamento"
        }).then((res) => { 
            if (res === true) {
                props.openSnackbar(`Publicação enviada para processamento.`);
            } else {
                props.openSnackbar(`Não foi possível enviar para processamento.`);
            }
        });
    };

    const handleClickDeletePub = () => {
        props.handleClose(false, undefined);
        f_publicacoes.update(props.publicacaoData.doc_id, {
            status: "ignorada"
        }).then((res) => {
            if (res === true) {
                props.openSnackbar(`Publicação ignorada.`);
            } else {
                props.openSnackbar(`Não foi possível ingorar a publicação.`);
            }
        });
    };

    if (props.publicacaoData === undefined || props.publicacaoData === null) {
        return (<React.Fragment></React.Fragment>)
    }
    
    return (
        <React.Fragment>
            <Dialog fullWidth maxWidth="lg" open={props.open}>
                <DialogTitle sx={{ m: 1, p: 0 }}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>

                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                NUM. PROCESSO (PUBLICAÇÃO): {props.publicacaoData.num_processo || "N/A"}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </DialogTitle>

                <DialogContent sx={{ m: 1, p: 1 }}>
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <InfosPaper>
                                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1">
                                            Assertividade: {props.publicacaoData.score || "N/A"}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1">
                                            Portal: {props.publicacaoData.portal || "N/A"}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1">
                                            Recurso: {props.publicacaoData.nome_recurso || "N/A"}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1">
                                            Data Disponibilização: {props.publicacaoData.data_disponibilizacao.toDate().toLocaleDateString('pt-BR') || "N/A"}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1">
                                            Data Publicação: {props.publicacaoData.data_publicacao.toDate().toLocaleDateString('pt-BR') || "N/A"}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1">
                                            Usuário: {props.publicacaoData.usuario_portal || "N/A"}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1">
                                            Status: {props.publicacaoData.status === "aguardando_processamento" ? "Publicação analisada e aguardando processamento." : props.publicacaoData.status === "pendente" ? "Publicação aguardando análise." : props.publicacaoData.status === "ignorada" ? "Publicação ignorada." : "Publicação processada."}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl sx={{ minWidth: 220 }}>
                                            <InputLabel>Motivo Inclusão</InputLabel>
                                            <Select
                                                value={tmpMotivoInclusao}
                                                onChange={(event) => handleSetMotivoInclusao(event)}
                                                fullWidth={true}
                                                label="Motivo Inclusão"
                                            >
                                                {props.motivosInclusaoList.map((motivo, index) => { return <MenuItem key={motivo + index + props.publicacaoData?.num_processo} value={motivo}>{motivo}</MenuItem> })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </InfosPaper>
                        </Grid>

                        { tmpMotivoInclusao === "PROCESSO ARQUIVADO" &&
                            <Grid item xs={12}>
                                <InfosPaper>
                                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                                                Motivo de arquivamento
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                maxRows={15}
                                                value={tmpDescricaoArquivamento}
                                                onChange={(event) => handleSetMotivoArquivamento(event) }
                                            />
                                        </Grid>
                                    </Grid>
                                </InfosPaper>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            {/* infos da publicação / comparação infos */}
                            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                                        Texto comparado
                                    </Typography>
                                    <ComparePaper variant="outlined">
                                        <Typography variant="subtitle2" gutterBottom>
                                            {highlightText(props.publicacaoData.texto_publicacao, props.publicacaoData.texto_comparativo)}
                                        </Typography>
                                    </ComparePaper>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                                        Texto publicação
                                    </Typography>
                                    <ComparePaper variant="outlined">
                                        <Typography variant="subtitle2" gutterBottom>
                                            {highlightText(props.publicacaoData.texto_comparativo, props.publicacaoData.texto_publicacao)}
                                        </Typography>
                                    </ComparePaper>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <InfosPaper>
                                <Grid container justifyContent="center" alignItems="center" spacing={8}>
                                    {(props.publicacaoData.status === "pendente" || props.publicacaoData.status === "ignorada") && 
                                        <Grid item>
                                            <Tooltip title="Enviar publicação para processamento" arrow>
                                                <Fab size="small" color="secondary" aria-label="enviar para processamento" >
                                                    <AddTask onClick={() => handleClickEnviarProcessamento()} />
                                                </Fab>
                                            </Tooltip>
                                        </Grid>
                                    }
                                    <Grid item>
                                        <Tooltip title="Ignorar publicação" arrow>
                                            <Fab size="small" color="warning" aria-label="ignorar publicação" >
                                                <DeleteForever onClick={() => handleClickDeletePub(true)} />
                                            </Fab>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </InfosPaper>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

ViewPublicacaoDialog.propTypes = {
    openSnackbar: PropTypes.func.isRequired,
    publicacaoData: PropTypes.object,
    motivosInclusaoList: PropTypes.array,
};

export default ViewPublicacaoDialog;